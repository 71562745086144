import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../../../../components/Icon'

const Wrapper = styled.div`
 z-index: 1300;
    position: fixed;
  left: 0;
  bottom: 0;   
  @media (max-width: 600px) {
     left: unset;
     right:0;
   }
 

`
const WrapperConfig = styled.div`

`
const ShowButton = styled.div`
cursor: pointer;

`
const CloseButton = styled.div`
cursor: pointer;
padding: 10px 10px 0px;
`
const LogoutLink = styled.a`
padding: 10px 10px 0px;
`

const StyledIcon = styled(Icon)`
height:20px;
`

const StagingConfigTemplate = ({...props}) => {
  const [stateShowTemplate, setStateShowTemplate] = useState({
    show:false
  })

  function handleShow(){

    if(stateShowTemplate.show){
      setStateShowTemplate({show:false})
    }else{
      setStateShowTemplate({show:true})

    }
  }

  return(
    <Wrapper {...props}>
      {stateShowTemplate.show && <WrapperConfig>

        <LogoutLink href={"/abmelden"}>Abmelden</LogoutLink>
        <CloseButton onClick={handleShow}>Schließen</CloseButton>
      </WrapperConfig>}
      {!stateShowTemplate.show && <ShowButton onClick={handleShow}><StyledIcon icon={"config"}></StyledIcon></ShowButton>}

    </Wrapper>
)}
StagingConfigTemplate.propTypes = {}
StagingConfigTemplate.defaultProps = {}
export default StagingConfigTemplate
